import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoContentComponent } from 'app/no-content/no-content.component';
import { BadRequestComponent } from 'app/bad-request/bad-request.component';
import { AlreadyAuthGuard } from 'app/shared/guards/already-auth.guard';

const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', loadChildren: () => import('./login/login.module').then((m) => m.LoginModule), canActivate: [AlreadyAuthGuard]},
    {path: 'registration', loadChildren: () => import('./sign-up/sign-up.module').then((m) => m.SignUpModule)},
    {path: 'forgotpassword', loadChildren: () => import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule)},
    {path: 'set_password', loadChildren: () => import('./set-password/set-password.module').then((m) => m.SetPasswordModule)},
    {path: 'verify', loadChildren: () => import('./verify-email/verify-email.module').then((m) => m.VerifyEmailModule)},
    {path: 'authorize', loadChildren: () => import('./authorize/authorize.module').then((m) => m.AuthorizeModule)},
    {path: 'google_connect', loadChildren: () => import('./google-connect/google-connect.module').then((m) => m.GoogleConnectModule)},
    {path: 'registration_google', loadChildren: () => import('./sign-up-google/sign-up-google.module').then((m) => m.GoogleSignUpModule)},
    {
        path: 'collaborator_invite',
        loadChildren: () => import('./collaborator-invite/collaborator-invite.module').then((m) => m.CollaboratorInviteModule),
    },
    {path: 'page-not-found', component: NoContentComponent},
    {path: 'bad-request', component: BadRequestComponent},
    {path: '**', redirectTo: 'page-not-found'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    declarations: [],
})

export class AppRoutingModule {
}
