import * as appSettings from './app-settings.action';

export interface State {
    'df-token': string;
    firstName: string;
    lastName: string;
    email: string;
}

export const initialState: State = {
    'df-token': null,
    firstName: null,
    lastName: null,
    email: null,
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state = initialState, action: appSettings.Actions): State {
    switch (action.type) {
    case appSettings.GET: {
        return state;
    }
    case appSettings.SET: {
        return Object.assign(state, action.payload);
    }
    default: {
        return state;
    }
    }
}

export const getAppSettings = (state: State) => state;
