import * as fromRouter from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import * as fromAppSettings from '../app-settings/app-settings.reducer';

export class State {
    public readonly router: fromRouter.RouterReducerState;
    public readonly appSettings: fromAppSettings.State;
}

export const reducers = {
    router: fromRouter.routerReducer,
    appSettings: fromAppSettings.reducer,
};

export const appSettingsState = (state: State) => state.appSettings;
export const getAppSettings = createSelector(appSettingsState, fromAppSettings.getAppSettings);
