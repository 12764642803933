import { Component, Injectable } from '@angular/core';
import { Notification, NotificationsService } from 'angular2-notifications';
import { icons } from './notification-icons';

const options = {
    position: ['top', 'right'],
    timeOut: 3000,
    lastOnBottom: false,
    animate: 'scale',
    maxLength: 200,
    theClass: 'notification-main',
    showProgressBar: false,
    pauseOnHover: false,
    clickToClose: true,
    icons: {
        success: '<span class="notification-icon">' + icons.success + '</span>',
        alert: '<span class="notification-icon">' + icons.alert + '</span>',
        error: '<span class="notification-icon">' + icons.error + '</span>',
    },
};

@Injectable()
export class NotifyService {
    public options = options;

    constructor(private _service: NotificationsService) {

    }

    public success(title, message, override?): Notification {
        return this._service.success(
            title,
            message,
            override,
        );
    }

    public error(title, message, override?): Notification {
        return this._service.error(
            title,
            message,
            override,
        );
    }

    public alert(title, message, override?): Notification {
        return this._service.alert(
            title,
            message,
            override,
        );
    }

}

// eslint-disable-next-line max-classes-per-file
@Component({
    selector: 'df-notification',
    template: '<simple-notifications [options]="options" (onCreate)="created()" (onDestroy)="destroyed()"></simple-notifications>',
})

export class NotificationComponent {
    public options = options;

    constructor(private _service: NotificationsService) {}

    public created() {
    // TODO [Denis] set event - fot push message to basket
    }

    // or
    public destroyed() {
    // TODO [Denis] set event - fot push message to basket
    }

}
