import { environment } from '@environment';

export const BASE_URL = location.host;
export const PROTOCOL = location.protocol;
export const FULL_BASE_URL = `${PROTOCOL}//${BASE_URL}`;
export const TENANT_APP_URL = `${FULL_BASE_URL}${environment.TENANT_APP_PATH}`;
export const PUBLIC_APP_URL = `${FULL_BASE_URL}${environment.PUBLIC_APP_PATH}`;
export const inputAreaMaxLenght = 70;
export const inputAreaMaxLenghtSmall = 20;
export const inputAreaMaxLenghtMiddle = 35;
export const inputAreaMaxLenghtDefault = 35;
export const inputAreaMaxLenghtLarge = 100;
export const textAreaMaxLenght = 400;
// eslint-disable-next-line max-len
export const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
