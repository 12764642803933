import { AbstractControl } from '@angular/forms';
import { ValidationResult } from './types';

export const dfNoOnlyWhitespacesValidator = (control: AbstractControl): ValidationResult => {
    const errors: {[key: string]: boolean} = {};
    if (control.value) {
        const isWhitespace = (control.value || '').trim().length === 0;
        if (isWhitespace) {
            errors.noOnlyWhitespaces = true;
        }
    }
    return errors;
};
