import { isDevMode, Pipe, PipeTransform } from '@angular/core';
import { LoggerService } from 'app/core';

@Pipe({name: 'dfDuration'})
export class DfDurationPipe implements PipeTransform {

    constructor(private logger: LoggerService) {}

    public transform(value: any, format?: string): string | null {
        let timeInSeconds: number;

        try {
            if (value === null || value <= 0) {
                timeInSeconds = 0;
            } else {
                timeInSeconds = parseInt(value, 10);
            }
            if (isNaN(timeInSeconds)) {
                throw new TypeError('YpDurationPipe - Invalid input.');
            }
        } catch (err) {
            if (isDevMode()) {
                this.logger.error(err);
            }
            return value;
        }

        if (format === 'seconds') {
            return timeInSeconds.toString();
        }

        const parsedTime: string = (new Date((timeInSeconds % 86400) * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];

        const duration = {
            days: Math.floor(timeInSeconds / 86400),
            hours: parseInt(parsedTime.match(/^(\d\d)/)[1], 10),
            minutes: parseInt(parsedTime.match(/:(\d\d):/)[1], 10),
            seconds: parseInt(parsedTime.match(/(\d\d)$/)[1], 10),
        };

        switch (format) {
        case 'days':
            return duration.days.toString();
        case 'hours':
            return (duration.days * 24 + duration.hours).toString();
        case 'minutes':
            return ((duration.days * 24 + duration.hours) * 60 + duration.minutes).toString();
        case 'short':
            return `${this.formatLeadingZero(duration.days * 24 + duration.hours)}:${this.formatLeadingZero(
                duration.minutes)}`;
        case 'full':
            return `${this.formatLeadingZero(duration.days * 24 + duration.hours)}:${this.formatLeadingZero(
                duration.minutes)}:${
                this.formatLeadingZero(duration.seconds)}`;
        default:
            // equals 'short'
            return `${this.formatLeadingZero(duration.days * 24 + duration.hours)}:${this.formatLeadingZero(
                duration.minutes)}`;
        }
    }

    private formatLeadingZero(num: number): string {
        return num < 10 ? `0${num}` : `${num}`;
    }
}
