<div class="app-wrapper fix-ie-flex-min-height">
  <df-notification></df-notification>
  <df-header-simple></df-header-simple>
  <div class="fix-ie-flex-footer">
    <router-outlet></router-outlet>
    <div #spinner class="loading-overlay">
      <df-spinner-component
        [spinnerShow]="true"
        [opaqueOverlay]="true"
      ></df-spinner-component>
    </div>
  </div>
  <df-footer></df-footer>
</div>
