import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[dfTosValidation]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: DfTosValidationDirective,
            multi: true,
        },
    ],
})

export class DfTosValidationDirective implements Validator {
    public validate(control: AbstractControl): {[key: string]: any} {
        if (control.value) {
            return null;
        } else {
            return {required: true};
        }
    }
}
