import { CurrencyPipe } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { environment } from '@environment';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from 'app/shared/store/reducers';
import { LoggerService } from './logger.service';

@NgModule({
    imports: [
        StoreModule.forRoot(reducers),
        StoreRouterConnectingModule.forRoot(),
        environment.production === false ? StoreDevtoolsModule.instrument() : [],
    ],
    exports: [
        StoreModule,
        StoreRouterConnectingModule,
        environment.production === false ? StoreDevtoolsModule : [],
    ],
    providers: [
        LoggerService,
        CurrencyPipe,
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only.');
        }
    }

    public static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
        };
    }
}
