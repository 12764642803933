import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

const API_BASE = `https://api.${environment.API_URL}`;

enum RequestMethod {
    Get = 0,
    Post = 1,
    Put = 2,
    Delete = 3,
    Options = 4,
    Head = 5,
    Patch = 6,
}

@Injectable()
export class HttpService {
    private token: any;

    constructor(private http: HttpClient, private cookieService: CookieService) { }

    public setToken(newToken) {
        this.token = newToken;
    }

    public get(url: string, isJsonResponse = false): any {
        return this.request(API_BASE + url, RequestMethod.Get, null, isJsonResponse);
    }

    public post(url: string, body: any, isJsonResponse = false): any {
        return this.request(API_BASE + url, RequestMethod.Post, body, isJsonResponse);
    }

    public put(url: string, body: any): Observable<ArrayBuffer> {
        return this.request(API_BASE + url, RequestMethod.Put, body);
    }

    public patch(url: string, body: any): Observable<ArrayBuffer> {
        return this.request(API_BASE + url, RequestMethod.Patch, body);
    }

    public delete(url: string): Observable<ArrayBuffer> {
        return this.request(API_BASE + url, RequestMethod.Delete);
    }

    public head(url: string): any {
        return this.request(API_BASE + url, RequestMethod.Head, null, true);
    }

    public options(url: string): Observable<ArrayBuffer> {
        return this.request(API_BASE + url, RequestMethod.Options);
    }

    public createHeader() {
        let headers: any;
        if (this.token !== undefined && this.token !== null && this.token !== '') {
            headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
            headers = headers.set('df-token', this.token);
        } else if (this.cookieService.check('df-token')) {
            const token = this.cookieService.get('df-token');
            headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
            headers = headers.set('df-token', token);
        } else {
            headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        }
        return headers;
    }

    public getOAuthCode(url: string): any {
        const headers = this.createHeader();
        return this.http.get(API_BASE + url, {headers});
    }

    public denyOauthCode(url: string, body?: any): any {
        const headers = this.createHeader();
        return this.http.post(API_BASE + url, body, {headers});
    }

    private request(url: string, method: RequestMethod, body?: any, isJsonResponse = false): any {
        let headers: any;
        if (this.token !== undefined && this.token !== null && this.token !== '') {
            headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
            headers = headers.set('df-token', this.token);
        } else {
            headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        }
        if (isJsonResponse === true) {

            if (method === RequestMethod.Get) {
                return this.http.get(url, {headers});
            }
            if (method === RequestMethod.Post) {
                return this.http.post(url, body, {headers});
            }
            if (method === RequestMethod.Head) {
                return this.http.head(url, {headers});
            }
        } else {
            if (method === RequestMethod.Get) {
                return this.http.get(url, {headers, observe: 'response', responseType: 'text'});
            }
            if (method === RequestMethod.Post) {
                return this.http.post(url, body, {headers, observe: 'response', responseType: 'text'});
            }
            if (method === RequestMethod.Head) {
                return this.http.head(url, {headers, observe: 'response', responseType: 'text'});
            }
        }
    }

}
