import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BASE_URL } from 'app/shared/constants/global';
import { NotifyService } from 'app/shared/services/notification.service';
import { RequestFactory } from 'app/shared/services/request.factory';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { PUBLIC_APP_URL } from 'app/shared/constants/global';
import { mergeMap, catchError } from 'rxjs/operators';

@Injectable()
export class OAuthGuard implements CanActivate {

    constructor(private cookieService: CookieService,
        private router: Router,
        private route: ActivatedRoute,
        private service: RequestFactory,
        private notifyService: NotifyService,
        private translation: TranslocoService) {
    }

    public canActivate(route: ActivatedRouteSnapshot) {
        const params = route.queryParams;
        const clientId = params['clientid'];
        const redirectURI = params['redirecturi'];
        const responseType = params['responsetype'];

        if (!redirectURI) {
            this.router.navigate(['/bad-request']);
            return false;
        }

        if (!responseType || !clientId) {
            window.location.href = `${redirectURI}?error=invalid_request`;
            return false;
        }

        if (responseType !== 'code') {
            window.location.href = `${redirectURI}?error=unsupported_response_type`;
            return false;
        }

        if (this.cookieService.check('df-token')) {
            const token = this.cookieService.get('df-token');
            this.service.checkDfTokenIsValid(token).pipe(
                mergeMap(() => this.service.checkClientId(clientId)),
            )
                .subscribe((response) => {
                    if (response) {
                        return true;
                    } else {
                        window.location.href = `${redirectURI}?error=unauthorized_client`;
                        return false;
                    }
                }, (error) => {
                    if (error.status === 500) {
                        window.location.href = `${redirectURI}?error=server_error`;
                        return false;
                    } else {
                        this.clearCookies();
                        const redirectUri = window.location.pathname + window.location.search;
                        window.location.href = `${PUBLIC_APP_URL}login?redirectUri=${encodeURIComponent(redirectUri)}`;
                        // return false;
                    }
                });
        } else {
            this.clearCookies();
            const redirectUri = window.location.pathname + window.location.search;
            window.location.href = `${PUBLIC_APP_URL}login?redirectUri=${encodeURIComponent(redirectUri)}`;
            // return false;
        }
        return true;
    }

    private clearCookies(): void {
        this.cookieService.delete('df-token', '/', BASE_URL);
    }
}
