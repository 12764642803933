import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { dfNoOnlyWhitespacesValidator } from 'app/shared/validators/no-only-whitespaces.validator';
import { ValidationResult } from '../validators/types';

@Directive({
    selector: '[dfNoOnlyWhitespacesValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: DfNoOnlyWhitespacesValidatorDirective, multi: true}],
})
export class DfNoOnlyWhitespacesValidatorDirective implements Validator {
    public validate = (control: AbstractControl): ValidationResult =>
        dfNoOnlyWhitespacesValidator(control);
}
