import { Component, Input } from '@angular/core';

@Component({
    selector: 'df-spinner-component',
    templateUrl: 'spinner.component.html',
    styleUrls: ['spinner.component.scss'],
})
export class SpinnerComponent {
    @Input() public spinnerShow: boolean;
    @Input() public opaqueOverlay = false;
    @Input() public position = 'center';
}
