import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';
import { AppService } from 'app/app.service';
import { UpdateService } from 'app/core';

@Component({
    selector: 'df-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        AppService,
    // UpdateService,
    ],
})

export class AppComponent implements AfterViewInit {
    @ViewChild('spinner', { static: true })
    public spinner: ElementRef;

    constructor(private translate: TranslocoService,
        // private updateService: UpdateService,
        private appService: AppService) {
        // this.updateService.init();
        translate.setAvailableLangs(['en', 'ru']);
        translate.setDefaultLang('en');

        const browserLang = getBrowserLang();
        // translate.setActiveLang(browserLang.match(/en|ru/) ? browserLang : 'en'); // Need to use after fill ru.json
        translate.setActiveLang('en'); // Need to delete after fill ru.json
    }

    public ngAfterViewInit(): void {
        this.appService.initRouterLoader(this.spinner);
    }
}
