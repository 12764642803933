import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { dfPasswordValidator } from '../validators/password.validator';
import { ValidationResult } from '../validators/types';

@Directive({
    selector: '[dfPasswordValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: DfPasswordValidatorDirective, multi: true}],
})
export class DfPasswordValidatorDirective implements Validator {
    public validate = (control: AbstractControl): ValidationResult =>
        dfPasswordValidator(control);
}
