<footer class="footer">
  <div class="footer__block footer__block--left">
    <a class="footer__link" [attr.href]="privacyPolicy">
      {{ 'FOOTER.MSG1' | transloco }}
    </a>
    <span class="spliter">|</span>
    <a class="footer__link" [attr.href]="termsAndConditions">
      {{ 'FOOTER.MSG2' | transloco }}
    </a>
  </div>
  <div class="footer__block footer__block--center">
    {{ 'FOOTER.DF2018' | transloco: {year: currentYear} }}
  </div>
  <div class="footer__block footer__block--right">
    <img class="logo" src="./assets/img/df_logo_white.svg">
  </div>
</footer>
