import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environment';

import { AppModule } from 'app/app.module';
import * as Sentry from '@sentry/browser';

let environmentName = 'local';
if (environment.production) {
  if (environment.API_URL === 'dev.dailyfrenzy.com') {
    environmentName = 'development';
  } else if (environment.API_URL === 'staging.dailyfrenzy.com') {
    environmentName = 'staging';
  } else if (environment.API_URL === 'dailyfrenzy.io') {
    environmentName = 'production';
  }
}

Sentry.init({
  dsn: 'https://067e98e2eb5c4f54b9bbf8b07fb93429@sentry.bitstudios.com/5',
  environment: environmentName,
  release: environment.CI_COMMIT_SHA,
  // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
  // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
  // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
  integrations: [
    new Sentry.Integrations.TryCatch({
      XMLHttpRequest: false,
    }),
  ],
});

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

bootstrap();

// TODO[Dmitry Teplov] Add SW after https://github.com/angular/angular/issues/21388 is resolved
navigator.serviceWorker?.getRegistration()?.then((registration) => {
  if (registration) {
    registration.unregister();
  }
});
