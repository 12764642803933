import { Component } from '@angular/core';
import { FULL_BASE_URL } from 'app/shared/constants/global';

@Component({
    selector: 'df-header-simple',
    templateUrl: './simple-header.component.html',
    styleUrls: ['./simple-header.component.scss'],
})

export class HeaderSimpleComponent {
    public readonly baseUrl: string = FULL_BASE_URL;
}
