import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'df-tos-checkbox',
    templateUrl: './tos-checkbox.component.html',
    styleUrls: ['./tos-checkbox.component.scss'],
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: TosCheckboxComponent, multi: true},
    ],
})
export class TosCheckboxComponent implements OnInit, ControlValueAccessor {

    @Input() targetHref: string;
    @Input() label: string;

    @Input() value: boolean;
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit() {
    }

    propagateChange: any = () => {};
    onTouched: any = () => {};

    public registerOnChange(fn) {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value) {
        this.value = value;
    }

    public check(newValue: boolean): void {
        this.valueChange.emit(newValue);
        this.propagateChange(newValue);
        this.onTouched();
        this.value = newValue;
    }

}
