import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
/* eslint-disable */
declare const grecaptcha: any;

declare global {
  interface Window {
    grecaptcha: any;
    reCaptchaLoad: () => void;
  }
}

/* eslint-enable */

export interface ReCaptchaConfig {
    theme?: 'dark' | 'light';
    type?: 'audio' | 'image';
    size?: 'compact' | 'normal';
    tabindex?: number;
}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({selector: '[recaptcha]'})
export class DfRecaptchaDirective implements OnInit {
    @Input() public reCaptchaKey: string;
    @Output() public captchaResponse = new EventEmitter<string>();
    @Output() public captchaExpired = new EventEmitter();
    private config: ReCaptchaConfig = {theme: 'light', type: 'image', size: 'normal'};
    private control: FormControl;
    private widgetId: number;

    constructor(private element: ElementRef) { }

    public ngOnInit() {
        this.registerReCaptchaCallback();
        this.addScript();
    }

    public addScript() {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    public registerReCaptchaCallback() {
        window.reCaptchaLoad = () => {
            const config = {
                ...this.config,
                sitekey: this.reCaptchaKey,
                callback: this.onSuccess.bind(this),
            };
            this.widgetId = this.render(this.element.nativeElement, config);
        };
    }

    public onSuccess(token: string) {
        this.captchaResponse.next(token);
    }

    public reset() {
        grecaptcha.reset(this.widgetId);
    }

    private render(element: HTMLElement, config): number {
        return grecaptcha.render(element, config);
    }
}
