import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BASE_URL } from 'app/shared/constants/global';
import { NotifyService } from 'app/shared/services/notification.service';
import { RequestFactory } from 'app/shared/services/request.factory';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AlreadyAuthGuard implements CanActivate {

    constructor(private cookieService: CookieService,
        private router: Router,
        private service: RequestFactory,
        private notifyService: NotifyService,
        private translation: TranslocoService) {
    }

    public canActivate() {
        if (this.cookieService.check('df-token')) {
            const token = this.cookieService.get('df-token');
            this.service.checkDfTokenIsValid(token).subscribe(() => {
                this.service.redirectToDashboard();
            }, (error) => {
                this.clearCookies();
                if (error.status === 400) {
                    this.translation.selectTranslate(['NOTIFICATIONS.WRONG_TOKEN_MSG1', 'NOTIFICATIONS.WRONG_TOKEN_MSG2'])
                        .subscribe((translations) => {
                            this.notifyService.error(translations[0],
                                translations[1]);
                        });
                }
                if (error.status === 500) {
                    this.translation.selectTranslate(['NOTIFICATIONS.ERROR', 'NOTIFICATIONS.SERVER_ERROR_TRY_AGAIN'])
                        .subscribe((translations) => {
                            this.notifyService.error(translations[0],
                                translations[1]);
                        });
                }
                this.router.navigate(['/login']);
            });
            return false;
        } else {
            this.clearCookies();
        }
        return true;
    }

    private clearCookies(): void {
        this.cookieService.delete('df-token', '/', BASE_URL);
    }
}
