import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { CoreModule, SentryErrorHandler, TranslocoRootModule } from 'app/core';
import { SpinnerComponentModule } from 'app/shared/components/spinner/spinner.module';
import { AlreadyAuthGuard } from 'app/shared/guards/already-auth.guard';
import { OAuthGuard } from 'app/shared/guards/oauth.guard';
import { AuthInterceptor } from 'app/shared/services/http-interceptor.service';
import { RequestFactory } from 'app/shared/services/request.factory';
import { SharedModule } from 'app/shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BadRequestComponent } from './bad-request/bad-request.component';
import { NoContentComponent } from './no-content/no-content.component';
import { HttpService } from './shared/services/http.service';
import { NotificationComponent, NotifyService } from './shared/services/notification.service';

@NgModule({
    bootstrap: [AppComponent],
    imports: [
        CoreModule.forRoot(),
        SharedModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        SimpleNotificationsModule.forRoot(),
        SpinnerComponentModule.forRoot(),
        HttpClientModule,
        NgbModule,
        TranslocoRootModule,
        ServiceWorkerModule.register('./ngsw-worker.js', {enabled: environment.production}),
    ],
    declarations: [
        AppComponent,
        BadRequestComponent,
        NoContentComponent,
        NotificationComponent,
    ],
    providers: [
        RequestFactory,
        HttpService,
        NotifyService,
        CookieService,
        AlreadyAuthGuard,
        OAuthGuard,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: ErrorHandler, useClass: SentryErrorHandler},
    ],
})
export class AppModule {
}
