import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SpinnerComponent } from './spinner.component';

export * from './spinner.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        SpinnerComponent,
    ],
    exports: [
        SpinnerComponent,
    ],
})
export class SpinnerComponentModule {
    public static forRoot(): ModuleWithProviders<SpinnerComponentModule> {
        return {
            ngModule: SpinnerComponentModule,
        };
    }
}
