import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { BASE_URL, FULL_BASE_URL, TENANT_APP_URL } from 'app/shared/constants/global';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SignUpModel } from '../models/sign-up.model';
import { HttpService } from './http.service';

const BASIC_URL = '/v1';
const ACCOUNT_BASIC_URL = '/account/v1';
const COLLABORATOR_URL = '/collaborator/v1/';

const catchAndThrow = (source$: Observable<any>) =>
    source$.pipe(
        catchError((error: any) => _throw(error || 'Server error')),
    );

@Injectable()
export class RequestFactory {
    constructor(private service: HttpService,
        private cookieService: CookieService,
        private httpService: HttpClient) {}

    public register(params: SignUpModel) {
        return this.service.post(BASIC_URL + '/register', params, true);
    }

    public checkDfTokenIsValid(token) {
        return this.service.head(`/check_token?token=${token}`)
            .pipe(catchAndThrow);
    }

    public login(params) {
        return this.service.post(BASIC_URL + '/login', params, true);
    }

    public checkIsEmailExist(param) {
        return this.service.post(BASIC_URL + '/registered_email_exists', param)
            .pipe(catchAndThrow);
    }

    public checkDomainExist(param) {
        return this.service.post(BASIC_URL + '/domain_exists', param, true)
            .pipe(catchAndThrow);
    }

    public resetPassword(param) {
        return this.service.post(BASIC_URL + '/password_reset', param);
    }

    public verifyEmail(param) {
        return this.service.post(ACCOUNT_BASIC_URL + '/verify_email', param)
            .pipe(catchAndThrow);
    }

    public getGoogleSignUpUrl() {
        return this.service.get(BASIC_URL + '/identity/oauth/google/registration_link', true)
            .pipe(catchAndThrow);
    }

    public getGoogleLoginUrl() {
        return this.service.get(BASIC_URL + '/identity/oauth/google/login_url', true)
            .pipe(catchAndThrow);
    }

    public getGoogleInfo(params) {
        return this.service.post(BASIC_URL + '/identity/oauth/google/init', params, true)
            .pipe(catchAndThrow);
    }

    public setNewPassword(params) {
        return this.service.post(BASIC_URL + '/password_new', params)
            .pipe(catchAndThrow);
    }

    public checkPasswordToken(params) {
        return this.service.post(BASIC_URL + '/check_password_token', params)
            .pipe(catchAndThrow);
    }

    public getInviteInfo(params) {
        return this.service.post(BASIC_URL + '/invite_info', params, true)
            .pipe(catchAndThrow);
    }

    public checkCollaboratorInvite(token: string) {
        return this.service.get(`${COLLABORATOR_URL}accept_token?token=${token}`, true)
            .pipe(catchAndThrow);
    }

    public processCollaboratorInvite(params) {
        return this.service.post(`${COLLABORATOR_URL}accept_token`, params)
            .pipe(catchAndThrow);
    }

    public redirectToDashboard(projectId?: number, boardId?: number) {
        if (projectId && boardId) {
            window.location.href = `${TENANT_APP_URL}projects/project/${projectId}/boards/${boardId}`;
        } else if (projectId) {
            window.location.href = `${TENANT_APP_URL}projects/project/${projectId}`;
        } else {
            window.location.href = `${TENANT_APP_URL}dashboard`;
        }
    }

    public redirectToTenant(redirectURI?: string, projectId?: number, boardId?: number) {
        if (redirectURI) {
            window.location.href =
        `${FULL_BASE_URL}${redirectURI.replace(/^\/*/, '/')}`;
        } else {
            this.redirectToDashboard(projectId, boardId);
        }
    }

    public redirectToConnect(state, code) {
        window.location.href = `${TENANT_APP_URL}google_connect?oauth=google&state=${state}&code=${code}`;
    }

    public logout(token: string) {
        this.httpService.get(
            `https://api.${environment.API_URL}${BASIC_URL}/logout`,
            {headers: {'df-token': token}},
        ).subscribe(() => {
            this.cookieService.delete('df-token', '/', BASE_URL);
        });
    }

    public getOauthCode(clientId, responseType, redirectURI, stateToken) {
        return this.service.getOAuthCode(
            `/df/oauth/authorize?clientid=${clientId}&responsetype=${responseType}&redirecturi=${redirectURI}&statetoken=${stateToken}`)
            .pipe(catchAndThrow);
    }

    public denyOauthCode(clientId) {
        const body = {};
        return this.service.denyOauthCode(`/df/oauth/deny?clientid=${clientId}`, body)
            .pipe(catchAndThrow);
    }

    public checkClientId(clientId) {
        return this.service.getOAuthCode(`/df/oauth/client?clientID=${clientId}`)
            .pipe(catchAndThrow);
    }
}
