import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { FooterComponent } from 'app/shared/components/footer/footer.component';
import { HeaderSimpleComponent } from 'app/shared/components/header-simple/simple-header.component';
import { PasswordStrengthBarComponent } from 'app/shared/components/password-strength/password-strength.component';
import { DfDebounceDirective } from 'app/shared/directives/debounce.directive';
import { DfDisableContextMenuDirective } from 'app/shared/directives/disable-context-menu.directive';
import { DfEmailValidatorDirective } from 'app/shared/directives/email-validation.directive';
import { DfHideKeyboardDirective } from 'app/shared/directives/hide-keyboard.directive';
import { DfLowercaseDirective } from 'app/shared/directives/lowercase.directive';
import { DfMouseWheelDirective } from 'app/shared/directives/mouse-wheel.directive';
import { DfNoOnlyWhitespacesValidatorDirective } from 'app/shared/directives/no-only-whitespaces-validator.directive';
import { DfPasswordValidatorDirective } from 'app/shared/directives/password-validator.directive';
import { DfRecaptchaDirective } from 'app/shared/directives/reCaptcha.directive';
import { DfCurrencyPipe } from 'app/shared/pipes/currency.pipe';
import { DfDurationPipe } from 'app/shared/pipes/duration.pipe';

import { ErrorMessageModule } from './components/error-message/error-message.module';
import { TosCheckboxComponent } from './components/tos-checkbox/tos-checkbox.component';
import { DfTosValidationDirective } from './components/tos-checkbox/tos-validation.directive';
import { DfDisableAutocompleteDirective } from './directives/disable-autocomplete.directive';
import { TranslateCutPipe } from 'app/shared/pipes/translateCut.pipe';

const DIRECTIVES = [
    DfDisableContextMenuDirective,
    DfHideKeyboardDirective,
    DfMouseWheelDirective,
    DfDebounceDirective,
    DfEmailValidatorDirective,
    DfLowercaseDirective,
    DfRecaptchaDirective,
    DfPasswordValidatorDirective,
    DfDisableAutocompleteDirective,
    DfNoOnlyWhitespacesValidatorDirective,
    DfTosValidationDirective,
];

const PIPES = [
    DfCurrencyPipe,
    DfDurationPipe,
    TranslateCutPipe,
];

const SERVICES = [];

const COMPONENTS = [
    FooterComponent,
    HeaderSimpleComponent,
    PasswordStrengthBarComponent,
    TosCheckboxComponent,
];

@NgModule({
    imports: [
        CommonModule,
        TranslocoModule,
        RouterModule,
        ErrorMessageModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        ErrorMessageModule,
        ...DIRECTIVES,
        ...PIPES,
        ...COMPONENTS,
    ],
    declarations: [
        ...DIRECTIVES,
        ...PIPES,
        ...SERVICES,
        ...COMPONENTS,
    ],
})

export class SharedModule {
    public static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
        };
    }
}
