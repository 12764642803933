import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { emailRegExp } from 'app/shared/constants/global';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[emailValidation]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: DfEmailValidatorDirective,
            multi: true,
        },
    ],
})

export class DfEmailValidatorDirective implements Validator {
    public validate(control: AbstractControl): {[key: string]: any} {
        return this.EmailValidator(emailRegExp)(control);
    }

    private EmailValidator(emailV: RegExp): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} => {
            if (control.value === undefined || control.value === '' || control.value === null) {
                return null;
            }
            const _value = control.value.trim();
            const valid = emailV.test(_value);
            return valid ? null : {invalidEmail: {value: _value}};
        };
    }
}
