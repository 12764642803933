import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ngModel][debounce]',
})

export class DfDebounceDirective implements OnInit {
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() public onDebounce = new EventEmitter<any>();
    @Input() public debounce = 500;

    private isFirstChange = true;

    constructor(public model: NgControl) {
    }

    public ngOnInit() {
        this.model.valueChanges
            .pipe(
                debounceTime(this.debounce),
            ).subscribe((modelValue) => {
                if (this.isFirstChange) {
                    this.isFirstChange = false;
                } else {
                    this.onDebounce.emit(modelValue);
                }
            });
    }
}
