import { Component } from '@angular/core';

@Component({
    selector: 'df-no-content',
    template: `
  <div class="wrapper">
    <h1>Page Not Found</h1>
  </div>
  `,
    styleUrls: ['./no-content.component.scss'],
})
export class NoContentComponent {

}
