import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ErrorMessageComponent } from './error-message.component';

@NgModule({
    imports: [
        CommonModule,
        TranslocoModule,
    ],
    declarations: [
        ErrorMessageComponent,
    ],
    exports: [
        ErrorMessageComponent,
    ],
})
export class ErrorMessageModule {
}
