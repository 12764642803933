import { Component, OnInit } from '@angular/core';
import { FULL_BASE_URL } from 'app/shared/constants/global';

@Component({
    selector: 'df-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})

export class FooterComponent implements OnInit {
    public readonly privacyPolicy: string = `${FULL_BASE_URL}/privacy-policy`;
    public readonly termsAndConditions: string = `${FULL_BASE_URL}/terms-and-conditions`;
    public currentYear = 2018;

    constructor() {
        this.currentYear = (new Date()).getFullYear();
    }

    ngOnInit() {
        this.currentYear = (new Date()).getFullYear();
    }
}
