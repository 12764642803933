import { Action } from '@ngrx/store';

export const SET = '[App-Settings] Set';
export const GET = '[App-Settings] Get';

export class SetAction implements Action {
    public readonly type = SET;

    constructor(public payload: any) {}
}

// eslint-disable-next-line max-classes-per-file
export class GetAction implements Action {
    public readonly type = GET;
}

export type Actions = SetAction
| GetAction;
