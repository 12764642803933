import { Component } from '@angular/core';

@Component({
    selector: 'df-bad-request',
    template: `
  <div class="wrapper">
    <h1>Bad Request</h1>
  </div>
  `,
    styleUrls: ['./bad-request.component.scss'],
})
export class BadRequestComponent {

}
