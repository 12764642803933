/** This template is used by /scripts/prebuild.js
 * to create ci stage specific environment
 */
export const environment = {
  production: true,
  hmr: false,
  TENANT_APP_PATH: '/df/',
  PUBLIC_APP_PATH: '/app/',
  API_URL: 'dailyfrenzy.io',
  CURRENCY: 'USD',
  CI_COMMIT_SHA: '61666ec8a3b4569095126ae7f79207f333d463a2',
};
