import { Pipe, PipeTransform } from '@angular/core';

/**
 * TranslateCutPipe can be used to split ngx-translation string into separate parts.
 *
 * In translation json file:
 * "key": "you can |click me| to do something"
 *
 * In template:
 * <span>
 *   {{ 'key' | transloco | translateCut:0 }}
 *   <a [routerLink]="linkTarget">{{ 'key' | transloco | translateCut:1 }}</a>
 *   {{ 'key' | transloco | translateCut:2 }}
 * </span>
 *
 * Result:
 * <span>you can <a [routerLink]="linkTarget">click me</a> to do something</span>
 */
@Pipe({name: 'translateCut'})
export class TranslateCutPipe implements PipeTransform {
    public transform(value: string, index: string): string {
        const cutIndex = Number(index);
        return isNaN(cutIndex)
            ? value
            : value.split('|')[cutIndex];
    }
}
