<div class="tos-wrapper">
  <div 
    *ngIf="!value"
    class="tos-not_checked"
    (click)="check(!value)"
  ></div>
  <div 
    *ngIf="value"
    class="tos-checked"
    (click)="check(!value)"
  ></div>
  <div class="label">
    {{ label | transloco | translateCut: 0}}
    <a 
      [href]="targetHref" 
      target="_blank" 
      class="terms"
      rel="noopener"
    >
      {{label | transloco | translateCut: 1}}
    </a>
  </div>
</div>