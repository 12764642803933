import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

const DEFAULT_READONLY_BG = '#fff';

@Directive({
    selector: '[dfDisableAutocomplete]',
})
export class DfDisableAutocompleteDirective implements OnInit {
    // eslint-disable-next-line  @angular-eslint/no-input-rename
    @Input('dfDisableAutocomplete') public readonlyBackgroundColor: string;

    private previousBackgroundColor: string;

    constructor(private el: ElementRef) {}

    public ngOnInit() {
        const bgColor: string = this.el.nativeElement.style.readonlyBackgroundColor;
        if (bgColor) {
            this.previousBackgroundColor = bgColor;
        }
        this.el.nativeElement.setAttribute('readonly', true);
        this.el.nativeElement.style.backgroundColor = (this.readonlyBackgroundColor || DEFAULT_READONLY_BG);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    @HostListener('focus')
    public onFocus() {
        this.el.nativeElement.removeAttribute('readonly');
        this.el.nativeElement.style.backgroundColor = (this.previousBackgroundColor || null);
    }
}
