import { ElementRef, Injectable, NgZone, Renderer2 } from '@angular/core';
import {
    Event as RouterEvent,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';

@Injectable()
export class AppService {
    private spinner: ElementRef;

    constructor(private router: Router,
        private ngZone: NgZone,
        private renderer: Renderer2) {}

    public initRouterLoader(spinnerRef: ElementRef): void {
        this.spinner = spinnerRef;
        this.hideSpinner();
        this.router.events.subscribe(
            (event: RouterEvent) => this.navigationInterceptor(event),
        );
    }

    // Shows and hides the loading spinner during RouterEvent changes
    private navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.showSpinner();
        }
        if (event instanceof NavigationEnd) {
            this.hideSpinner();
        }
        // Set loading state to false in both of the below events to
        // hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.hideSpinner();
        }
        if (event instanceof NavigationError) {
            this.hideSpinner();
        }
    }

    private showSpinner(): void {
    // We wanna run this function outside of Angular's zone to
    // bypass change detection
        this.ngZone.runOutsideAngular(() => {
            // For simplicity we are going to turn display on / off
            // you could add/remove a class for more advanced styling
            // and enter/leave animation of the spinner
            this.renderer.setStyle(
                this.spinner.nativeElement,
                'display',
                'inherit',
            );
        });
    }

    private hideSpinner(): void {
    // We wanna run this function outside of Angular's zone to
    // bypass change detection,
        this.ngZone.runOutsideAngular(() => {
            // For simplicity we are going to turn display on / off
            // you could add/remove a class for more advanced styling
            // and enter/leave animation of the spinner
            this.renderer.setStyle(
                this.spinner.nativeElement,
                'display',
                'none',
            );
        });
    }
}
