import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationsService } from 'angular2-notifications';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private cookieService: CookieService, private notificationService: NotificationsService,
        private injector: Injector) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                tap((resp) => {
                    if (resp instanceof HttpResponse) {
                        const headers: HttpHeaders = resp.headers;
                        if (headers.get('maintenance-status') && headers.get('location')) {
                            const redirectLocation = headers.get('location');
                            const maintenanceStatus = headers.get('maintenance-status');
                            if (maintenanceStatus === 'true') {
                                window.location.href = redirectLocation;
                            }
                        }
                    }
                }, (err) => {
                    Sentry.captureException(err);
                    if (err instanceof HttpErrorResponse && err.status >= 500) {
                        this.handleServerErrors();
                    }
                }),
            );
    }

    private handleServerErrors() {
    // can't use DI because of circular dependency HttpInterceptor -> TranslateService -> HttpClient -> HttpInterceptor
        const translateService = this.injector.get(TranslocoService);
        translateService.selectTranslate(['NOTIFICATIONS.ERROR', 'NOTIFICATIONS.SERVER_ERROR_TRY_AGAIN']).subscribe((translations) => {
            this.notificationService.error(translations[0],
                translations[1]);
        });
    }
}
