<ng-container *ngIf="spinnerShow">
  <div
    class="sc-background"
    [class.sc-bg-opaque]="opaqueOverlay"
  ></div>
  <div class="sc-center">
    <div #ref [class]="'spinner-container spinner-container-' + position">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
  </div>
</ng-container>
